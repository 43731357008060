export function neonMultiColorEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const neonElements = document.querySelectorAll(selector);

        if (neonElements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes neon-multi {
                0% {
                    text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red;
                    color: red;
                }
                50% {
                    text-shadow: 0 0 10px yellow, 0 0 20px yellow, 0 0 30px yellow;
                    color: yellow;
                }
                100% {
                    text-shadow: 0 0 10px green, 0 0 20px green, 0 0 30px green;
                    color: green;
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        neonElements.forEach(element => {
            element.style.animation = 'neon-multi 3s infinite alternate';
        });
    });
}
