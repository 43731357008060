import * as d3 from 'd3';
import cloud from 'd3-cloud';
import { applyNeonEffect } from './neon-effect';

export function drawPhraseCloud(
    dataObject,
    cloudElement
) {
    let padding = { top: 25, bottom: 7, left: 50, right: 50 };
    let margin = { top: 0, bottom: 0, left: 0, right: 0 };

    d3.select(cloudElement).selectAll("*").remove();

    const aspectRatio = dataObject.width / dataObject.height;

    let maxFontSize;
    let minFontSize;
    let { rotateRatio, rotationSteps, rotateFunc } = dataObject.rotate;

    if (aspectRatio > 1) {
        maxFontSize = Math.min(100, dataObject.width / 5);
        minFontSize = dataObject.width / 30;
    } else {
        maxFontSize = Math.min(80, dataObject.width / 8);
        minFontSize = dataObject.width / 40;
    }

    const dynamicScale = d3.scaleLinear()
        .domain([0, dataObject.phrases.length - 1])
        .range([maxFontSize, minFontSize]);

    let wordsToPlace = dataObject.phrases.map((d, i) => ({
        text: d.phrase,
        size: dynamicScale(i),
        attempts: 0,
        class: dataObject.chosenNeonEffect || (dataObject.neonEffects.length > 0 ? dataObject.neonEffects[Math.floor(Math.random() * dataObject.neonEffects.length)] : ''),
        url: d.url
    }));

    function luminance(hex) {
        const rgb = hexToRgb(hex);
        const a = rgb.map(function (v) {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    function invertColor(hex) {
        const rgb = hexToRgb(hex);
        const invertedRgb = rgb.map(v => 255 - v);
        return rgbToHex(invertedRgb);
    }

    function hexToRgb(hex) {
        let bigint = parseInt(hex.slice(1), 16);
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    }

    function rgbToHex(rgb) {
        return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
    }

    function getContrastingColor(textColor, bgColor) {
        const textColorLuminance = luminance(textColor);
        const bgColorLuminance = luminance(bgColor);
        return Math.abs(textColorLuminance - bgColorLuminance) > 0.5 ? textColor : invertColor(textColor);
    }

    function getValidTextColor(colors, bgColor) {
        const validColors = colors.filter(color => color !== bgColor).map(color => getContrastingColor(color, bgColor));
        return validColors[Math.floor(Math.random() * validColors.length)];
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    function placeWords() {
        const adjustedHeight = dataObject.height - padding.top - padding.bottom;

        if (aspectRatio <= 1) {
            drawVerticalLayout(wordsToPlace, adjustedHeight);
        } else {
            const layout = cloud()
                .size([dataObject.width - padding.left - padding.right, adjustedHeight])
                .words(wordsToPlace)
                .padding(5)
                .rotate(() => {
                    const angle = rotateFunc ? rotateFunc() : Math.floor(Math.random() * rotationSteps) * (rotateRatio > 0 ? 90 / rotationSteps : 0);
                    return angle || 0;
                })
                .font(dataObject.font || 'Arial')
                .fontSize(d => d.size)
                .random(() => Math.random())
                .on("word", (word, bounds) => {
                    if (!bounds) {
                        word.attempts += 1;
                        if (word.attempts < 5) {
                            word.size *= 0.9;
                            layout.words(wordsToPlace.map(p => p));
                        }
                    }
                })
                .on("end", draw);

            layout.start();
        }
    }

    function draw(words) {
        const svg = d3.select(cloudElement).append("svg")
            .attr("width", dataObject.width)
            .attr("height", dataObject.height)
            .attr("viewBox", `0 0 ${dataObject.width} ${dataObject.height}`)
            .attr("preserveAspectRatio", "xMidYMid meet");

        if (dataObject.backgroundColor.includes('linear-gradient')) {
            const gradientId = `bg-gradient-${Math.random().toString(36).substr(2, 9)}`;
            const gradientDef = svg.append("defs")
                .append("linearGradient")
                .attr("id", gradientId)
                .attr("gradientUnits", "userSpaceOnUse")
                .attr("x1", "0%")
                .attr("y1", "0%")
                .attr("x2", "100%")
                .attr("y2", "100%");

            const colors = dataObject.backgroundColor.match(/#[a-fA-F0-9]{6}/g);
            gradientDef.append("stop").attr("offset", "0%").attr("stop-color", colors[0]);
            gradientDef.append("stop").attr("offset", "100%").attr("stop-color", colors[1]);

            svg.style("background", `url(#${gradientId})`);
        } else if (cloudElement.style.backgroundImage && cloudElement.style.backgroundImage !== 'none') {
            svg.style("background-image", cloudElement.style.backgroundImage);
            svg.style("background-size", cloudElement.style.backgroundSize);
        } else {
            svg.style("background-color", dataObject.backgroundColor);
        }

        svg.style("margin", `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`);

        const text = svg.append("g")
            .attr("transform", `translate(${dataObject.width / 2},${(dataObject.height - padding.top) / 2})`)
            .selectAll("text")
            .data(words)
            .enter().append("a")
            .append("text")
            .attr("class", d => d.class)
            .style("font-family", dataObject.font || 'Arial')
            .style("font-size", d => d.size + "px")
            .style("fill", (d, i) => {
                const colors = dataObject.colorPalette || ['#000000'];
                const color = getValidTextColor(colors, dataObject.backgroundColor);
                return color;
            })
            .style("text-anchor", "middle")
            .style("text-decoration", "none") 
            .attr("transform", d => `translate(${d.x},${d.y + padding.top})rotate(${d.rotate || 0})`)
            .text(d => d.text)
            .style("cursor", d => d.url ? "pointer" : "default")
            .style("text-shadow", `${dataObject.shadowStyle.shadowOffsetX}px ${dataObject.shadowStyle.shadowOffsetY}px ${dataObject.shadowStyle.shadowBlur}px ${dataObject.shadowStyle.shadowColor}`)
            .on("click", function(event, d) {
                if (d.url) {
                    window.location.href = d.url;
                } else {
                    const phraseData = dataObject.phrases.find(p => p.phrase === d.text);
                    if (phraseData) {
                        phraseData.weight = (phraseData.weight || 1) * 1.2;
                        d.size = scale(phraseData.weight);
                        d3.select(cloudElement).selectAll("*").remove();
                        drawPhraseCloud(
                            dataObject,
                            cloudElement
                        );
                    }
                }
            });
    }

    function drawVerticalLayout(words, adjustedHeight) {
        const svg = d3.select(cloudElement).append("svg")
            .attr("width", dataObject.width)
            .attr("height", dataObject.height)
            .attr("viewBox", `0 0 ${dataObject.width} ${dataObject.height}`)
            .attr("preserveAspectRatio", "xMidYMid meet");

        const uniformFontSize = Math.min(dataObject.width, adjustedHeight) / (words.length + 1);

        svg.style("margin", `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`);

        const text = svg.selectAll("text")
            .data(words)
            .enter().append("a")
            .append("text")
            .attr("class", d => d.class)
            .style("font-family", dataObject.font || 'Arial')
            .style("font-size", uniformFontSize + "px")
            .style("fill", (d, i) => {
                const colors = dataObject.colorPalette || ['#000000'];
                const color = getValidTextColor(colors, dataObject.backgroundColor);
                return color;
            })
            .style("text-anchor", "middle")
            .style("text-decoration", "none") 
            .attr("x", dataObject.width / 2)
            .attr("y", (d, i) => padding.top + (i + 1) * (adjustedHeight / (words.length + 1)))
            .text(d => d.text)
            .style("cursor", d => d.url ? "pointer" : "default")
            .style("text-shadow", `${dataObject.shadowStyle.shadowOffsetX}px ${dataObject.shadowStyle.shadowOffsetY}px ${dataObject.shadowStyle.shadowBlur}px ${dataObject.shadowStyle.shadowColor}`)
            .on("click", function(event, d) {
                if (d.url) {
                    window.location.href = d.url;
                } else {
                    const phraseData = dataObject.phrases.find(p => p.phrase === d.text);
                    if (phraseData) {
                        phraseData.weight = (phraseData.weight || 1) * 1.2;
                        d.size = scale(phraseData.weight);
                        d3.select(cloudElement).selectAll("*").remove();
                        drawPhraseCloud(    
                            dataObject,
                            cloudElement);
                    }
                }
            });
    }

    placeWords();
    applyNeonEffect(dataObject.chosenNeonEffect);
}
