export function neonBlinkEffect(selector) {
    console.log('B.selector: ' + selector);
    document.addEventListener('DOMContentLoaded', function () {
        const neonElements = document.querySelectorAll('.neon-blink');
        console.log('C.selector: ' + selector);
        if (neonElements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes blink {
                0%, 49%, 100% {
                    text-shadow: none;
                }
                50% {
                    text-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.7), 0 0 30px rgba(0, 255, 0, 0.7);
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        neonElements.forEach(element => {
            element.style.animation = 'blink 1s infinite alternate';
        });
    });
}
