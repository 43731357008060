export function neonShakeEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes shake {
                0%, 100% {
                    transform: translateX(0);
                }
                25% {
                    transform: translateX(-3px);
                }
                50% {
                    transform: translateX(3px);
                }
                75% {
                    transform: translateX(-1px);
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.animation = 'shake 0.5s infinite';
            element.style.textShadow = '0 0 10px rgba(255, 0, 0, 0.9), 0 0 20px rgba(255, 0, 0, 0.7)';
            element.style.color = '#ff0000'; // neon red
        });
    });
}
