export function neonPulseEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes pulse {
                0%, 100% {
                    text-shadow: 0 0 20px rgba(0, 255, 127, 0.7), 0 0 30px rgba(0, 255, 127, 0.5);
                }
                50% {
                    text-shadow: 0 0 30px rgba(0, 255, 127, 1), 0 0 40px rgba(0, 255, 127, 0.8);
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.animation = 'pulse 2s infinite alternate';
            element.style.color = '#00ff7f'; // neon green
        });
    });
}
