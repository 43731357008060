export function applyShapeMask(imageShape, useMask, width, height, callback) {
    if (!useMask || !imageShape) { 
        console.log("Masking is disabled or no imageShape provided. Skipping image processing.");
        callback(null);
        return;
    }

    const image = new Image();
    image.src = imageShape;
    image.onload = function() {
        console.log("Image loaded successfully:", imageShape);

        const scale = Math.min(1, width / image.width, height / image.height);
        const scaledWidth = Math.floor(image.width * scale);
        const scaledHeight = Math.floor(image.height * scale);

        console.log(`Scaling image to: ${scaledWidth}x${scaledHeight}`);

        const canvas = document.createElement('canvas');
        canvas.width = scaledWidth;
        canvas.height = scaledHeight;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, scaledWidth, scaledHeight);

        console.log("Canvas created with dimensions:", scaledWidth, scaledHeight);

        try {
            const imageData = context.getImageData(0, 0, scaledWidth, scaledHeight).data;
            const mask = [];
            for (let y = 0; y < scaledHeight; y++) { 
                for (let x = 0; x < scaledWidth; x++) { 
                    const i = (y * scaledWidth + x) * 4;
                    if (imageData[i + 3] > 128) { // Sprawdzenie przezroczystości
                        mask.push([x - scaledWidth / 2, y - scaledHeight / 2]);
                    }
                }
            }
            console.log("Mask generated with length:", mask.length);
            callback(mask);
        } catch (error) {
            console.error("Error processing image data:", error);
            callback(null);
        }
    };
    image.onerror = function() {
        console.error("Failed to load image:", imageShape);
        callback(null);
    };
}
