export function neonFadeInOutEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes fadeInOut {
                0%, 100% {
                    text-shadow: none;
                    opacity: 0.7;
                }
                50% {
                    text-shadow: 0 0 10px rgba(255, 165, 0, 0.9), 0 0 20px rgba(255, 165, 0, 0.7), 0 0 30px rgba(255, 165, 0, 0.5);
                    opacity: 1;
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.animation = 'fadeInOut 3s infinite';
            element.style.color = '#ffa500'; // neon orange
        });
    });
}
