import { setupPhraseCloud } from './modules/setup';
import { getShadowStyle } from './modules/shadow';
import { getRotationSettings } from './modules/rotation';
import { applyShapeMask } from './modules/shape-mask';
import { drawPhraseCloud } from './modules/draw';
import { getPalette } from './modules/palette';
import { image } from 'd3';

function setCloudSize(cloudElement, cloudSize) {
    console.log("Setting cloud size to:", cloudSize); // Debug log

    if (cloudSize === 'full') {
        // Pobierz wysokość menu
        const menuElement = document.querySelector('#main-header'); // Upewnij się, że ten selektor odpowiada Twojemu menu
        const menuHeight = menuElement ? menuElement.offsetHeight : 0;

        // Ustaw wysokość chmury fraz na pełne okno przeglądarki minus wysokość menu
        cloudElement.style.width = '100vw';
        cloudElement.style.height = `calc(100vh - ${menuHeight}px)`;
    } else {
        cloudElement.style.width = '100%';
        cloudElement.style.height = '100%';
    }
}

function renderPhraseCloud(objectDataCloud) {
    if (!objectDataCloud) {
        console.error("Data for rendering cloud not provided.");
        return;
    }

    if (!objectDataCloud.phrases || objectDataCloud.phrases.length === 0) {
        console.error("No phrases to display.");
        return;
    }

    const cloudElement = document.getElementById('phrase-cloud');
    if (!cloudElement) {
        console.error("Element with ID 'phrase-cloud' not found.");
        return;
    }

    // Ustaw rozmiar chmury na podstawie opcji przekazanej z danych
    setCloudSize(cloudElement, objectDataCloud.cloudSize);

    // Clear previous content to avoid stacking elements
    cloudElement.innerHTML = '';

    const { width, height, fontSize } = setupPhraseCloud();
    const shadowStyle = getShadowStyle(objectDataCloud.shadow);
    const rotate = getRotationSettings(
        objectDataCloud.rotation, 
        objectDataCloud.customAngles,
        objectDataCloud.customAngles2);
    const fill = getPalette(objectDataCloud.palette);

    const neonEffects = objectDataCloud.neonEffects.length > 0 ? objectDataCloud.neonEffects : [];
    console.log("Neon Effects:", neonEffects); // Debug log
    const chosenNeonEffect = objectDataCloud.chosenNeonEffect === 'none' ? '' : 
        (objectDataCloud.chosenNeonEffect === 'random' ? neonEffects[Math.floor(Math.random() * neonEffects.length)] :
        (objectDataCloud.chosenNeonEffect === 'random-each' ? null : objectDataCloud.chosenNeonEffect));
    console.log("Chosen Effect:", chosenNeonEffect); // Debug log

    const innerContainer = document.createElement('div');
    innerContainer.style.padding = objectDataCloud.padding;  
    innerContainer.style.margin = objectDataCloud.margin;  
    innerContainer.style.boxSizing = "border-box";
    innerContainer.style.backgroundSize = objectDataCloud.backgroundSize;
    innerContainer.style.backgroundRepeat = "no-repeat";
    innerContainer.style.backgroundImage = `url('${objectDataCloud.backgroundImage}')`;

    // Append the new innerContainer
    cloudElement.appendChild(innerContainer);

    const padding = objectDataCloud.padding ?? { top: "50px", bottom: "150px", left: "0", right: "0" };

    const dataObject = {
        phrases: objectDataCloud.phrases,
        font: objectDataCloud.font,
        width: cloudElement.clientWidth,
        height: cloudElement.clientHeight,
        colorPalette: fill,
        shadowStyle: shadowStyle,
        rotate: rotate,
        backgroundColor: objectDataCloud.bgColor,
        backgroundTransparent: objectDataCloud.bgTransparent,
        neonEffects: neonEffects, 
        chosenNeonEffect: chosenNeonEffect,
        padding: objectDataCloud.padding,
        mask: ''
    };

    applyShapeMask(
        objectDataCloud.imageShape, 
        true, 
        cloudElement.clientWidth, 
        cloudElement.clientHeight, 
        (mask) => {
            dataObject.mask = mask;
            drawPhraseCloud(
                dataObject, 
                innerContainer
            );
        });
}

function resizeCanvasToWindowSize() {
    const cloudElement = document.getElementById('phrase-cloud');
    if (!cloudElement) return;

    const cloudSize = window.phraseCloudData ? window.phraseCloudData.cloudSize : 'parent';
    setCloudSize(cloudElement, cloudSize);

    if (typeof window.phraseCloudData !== 'undefined') {
        renderPhraseCloud(
            window.phraseCloudData
        );
    }
}

window.addEventListener('resize', resizeCanvasToWindowSize);

document.addEventListener('DOMContentLoaded', function() {
    resizeCanvasToWindowSize();
});
