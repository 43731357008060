export function neonGlowEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes glow {
                0%, 100% {
                    text-shadow: 0 0 20px rgba(0, 255, 0, 1), 0 0 30px rgba(0, 255, 0, 0.7), 0 0 40px rgba(0, 255, 0, 0.5);
                }
                50% {
                    text-shadow: 0 0 30px rgba(0, 255, 0, 1), 0 0 50px rgba(0, 255, 0, 0.8), 0 0 70px rgba(0, 255, 0, 0.6);
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.animation = 'glow 1.5s infinite alternate';
            element.style.color = '#00ff00'; // neon green
        });
    });
}
