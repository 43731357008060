import { gsap } from "gsap";

export function neonBackShadowEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements found.');
        return;
    }

    elements.forEach(element => {
        // Początkowa konfiguracja z GSAP
        gsap.set(element, {
            color: '#ff69b4',  // Neonowy różowy kolor
            textShadow: '0 0 10px rgba(255, 105, 180, 0.8), -2px -2px 15px rgba(255, 105, 180, 0.5)',
            cursor: 'pointer'  // Zmieniony kursor
        });

        // Animacja cienia z GSAP
        const tl = gsap.timeline({
            repeat: -1,  // Nieskończona pętla
            yoyo: true,  // Powrót animacji
            ease: "power1.inOut",  // Płynne przejścia
            duration: 2  // Czas trwania animacji
        });

        // Animacja cienia
        tl.to(element, {
            textShadow: '0 0 20px rgba(255, 105, 180, 1), -4px -4px 25px rgba(255, 105, 180, 0.7)',
        });

        // Hover efekt
        element.addEventListener('mouseenter', () => {
            // Pauza animacji podczas hovera
            tl.pause();
            console.log('1111111');
            // Zmiana koloru na hover i dostosowanie cienia
            gsap.to(element, {
                duration: 0.2,
                color: '#00ffff',  // Zmiana koloru na cyjan
                textShadow: '0 0 20px rgba(0, 255, 255, 1), -4px -4px 25px rgba(0, 255, 255, 0.7)',  // Dopasowanie cienia do koloru
                overwrite: true  // Zapewnienie priorytetu tej animacji
            });
        });

        // Reset koloru po opuszczeniu elementu
        element.addEventListener('mouseleave', () => {
            // Powrót do oryginalnego koloru
            gsap.to(element, {
                duration: 0.2,
                color: '#ff69b4',  // Powrót do neonowego różu
                textShadow: '0 0 10px rgba(255, 105, 180, 0.8), -2px -2px 15px rgba(255, 105, 180, 0.5)',  // Powrót do oryginalnego cienia
                overwrite: true,  // Zapewnienie priorytetu tej animacji
                onComplete: () => tl.resume()  // Wznowienie animacji po opuszczeniu
            });
        });
    });
}
