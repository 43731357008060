export function getRotationSettings(rotationType, customAngles, customAngles2) {
    let rotateRatio = 0;
    let rotationSteps = 0;
    let rotateFunc = null;

    if (rotationType === 'random-modes') {
        const modes = ['horizontal', 'horizontal-full', 'vertical', 'fixed', 'random-simple', 'random', 'custom'];
        rotationType = modes[Math.floor(Math.random() * modes.length)];
        console.log("Random Modes selected:", rotationType);
    }

    switch (rotationType) {
        case 'horizontal':
            rotateRatio = 0;
            rotationSteps = 1;
            rotateFunc = () => 0;
            break;
        case 'horizontal-full':
            rotateRatio = 0.3;
            rotationSteps = 4;
            rotateFunc = () => Math.random() < 0.5 ? 0 : 180;
            break;
        case 'vertical':
            rotateRatio = 1;
            rotationSteps = 2;
            rotateFunc = () => 90;
            break;
        case 'fixed':
            rotateRatio = 0.5;
            rotationSteps = 2;
            rotateFunc = () => 0;
            break;
        case 'random':
            rotateRatio = 1;
            rotationSteps = 360;
            rotateFunc = () => Math.floor(Math.random() * 360);
            break;
        case 'random-simple':
            rotateRatio = 1;
            rotationSteps = 4;
            rotateFunc = () => {
                const angles = [0, 90, 180, 270];
                return angles[Math.floor(Math.random() * angles.length)];
            };
            break;
        case 'custom':
            rotateRatio = 1;
            rotationSteps = 0;
            rotateFunc = () => {
                const angles = [parseInt(customAngles, 10), parseInt(customAngles2, 10)].filter(a => !isNaN(a));
                return angles[Math.floor(Math.random() * angles.length)];
            };
            break;
        default:
            rotateRatio = 0;
            rotationSteps = 1;
            rotateFunc = () => 0;
            break;
    }

    // Zapewnienie, że rotateFunc jest zawsze zdefiniowana
    if (!rotateFunc) {
        rotateFunc = () => 0; // Domyślny kąt rotacji to 0 stopni
    }

    return { rotateRatio, rotationSteps, rotateFunc };
}
