export function neonStaticEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        elements.forEach(element => {
            element.style.textShadow = '0 0 10px rgba(173, 216, 230, 0.8), 0 0 20px rgba(173, 216, 230, 0.5)';
            element.style.color = '#add8e6'; // neon light blue
        });
    });
}
