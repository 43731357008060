export function getPalette(palette) {
    const palettes = {
        'warm': ["#FF5733", "#FF8D1A", "#FFC300", "#FF4500", "#4D2600"],  // Last color darkened
        'cool': ["#33FFCE", "#33A1FF", "#335BFF", "#33C4FF", "#002244"],  // Last color darkened
        'monochrome': ["#000000", "#444444", "#888888", "#BBBBBB", "#FFFFFF"],  // Last color lightened
        'forest': ["#228B22", "#6B8E23", "#556B2F", "#8FBC8F", "#0D330D"],  // Last color darkened
        'sunset': ["#FF4500", "#FF6347", "#FF7F50", "#FFD700", "#661700"],  // Last color darkened
        'ocean': ["#1E90FF", "#00BFFF", "#4682B4", "#5F9EA0", "#001F3F"],  // Last color darkened
        'desert': ["#EDC9AF", "#FFD700", "#D2B48C", "#F4A460", "#8B4513"],  // Last color darkened
        'pastel': ["#FFB6C1", "#FFDAB9", "#E6E6FA", "#FFFACD", "#2B2B2B"],  // Last color darkened significantly
        'retro': ["#B5651D", "#FF7F50", "#FFD700", "#DEB887", "#301400"],  // Last color darkened
        'neon': ["#39FF14", "#FF1493", "#FFD700", "#00FFFF", "#2F2F2F"],  // Last color darkened
        'earth': ["#8B4513", "#A0522D", "#D2691E", "#CD853F", "#F5F5DC"],  // Last color lightened
        'linis-it-1': ["#ffffff", "#e9e9e9", "#f7f1e6", "#fcfaf6", "#333333"],  // Last color darkened
        'linis-it-2': ["#ffffff", "#fefdfc", "#fcfaf6", "#f7f1e6", "#222222"],  // Last color darkened
        'linis-it-3': ["#e9e9e9", "#f7f1e6", "#fefdfc", "#fcfaf6", "#111111"],  // Last color darkened significantly
        'linis-it-4': ["#f7f1e6", "#fcfaf6", "#ffffff", "#e9e9e9", "#000000"],  // Last color black for high contrast
        'linis-it-5': ["#fcfaf6", "#f7f1e6", "#fefdfc", "#e9e9e9", "#000000"],  // Last color black for high contrast
        'gradient-linis-it-1': ["#f7f1e6", "#fcfaf6", "#ffffff", "#333333"],  // Last color darkened
        'gradient-linis-it-2': ["#e9e9e9", "#fefdfc", "#ffffff", "#000000"],  // Last color black for high contrast
        'gradient-linis-it-3': ["#fefdfc", "#ffffff", "#eaeaea", "#000000"],  // Last color black for high contrast
        'gradient-warm': ["#FF5733", "#FF8D1A", "#FFC300", "#4D2600"],  // Last color darkened
        'gradient-cool': ["#33FFCE", "#33A1FF", "#335BFF", "#002244"],  // Last color darkened
        'gradient-forest': ["#228B22", "#6B8E23", "#8FBC8F", "#0D330D"],  // Last color darkened
        'gradient-sunset': ["#FF4500", "#FF6347", "#FFD700", "#661700"],  // Last color darkened
        'gradient-ocean': ["#1E90FF", "#00BFFF", "#4682B4", "#001F3F"],  // Last color darkened
        'gradient-desert': ["#EDC9AF", "#FFD700", "#F4A460", "#8B4513"]   // Last color darkened
    };

    if (palette === 'random') {
        const keys = Object.keys(palettes);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        return palettes[randomKey];
    } else if (palettes[palette]) {
        return palettes[palette];
    } else {
        return ["#000000", "#FFFFFF"]; // Domyślna paleta
    }
}
