export function neonExpandEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const neonElements = document.querySelectorAll(selector);

        if (neonElements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes expand {
                0%, 100% {
                    text-shadow: 0 0 10px rgba(128, 0, 128, 0.5);
                    letter-spacing: normal;
                }
                50% {
                    text-shadow: 0 0 30px rgba(128, 0, 128, 1);
                    letter-spacing: 2px;
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        neonElements.forEach(element => {
            element.style.animation = 'expand 2s infinite alternate';
        });
    });
}
