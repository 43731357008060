export function setupPhraseCloud() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const maxWeight = 100;
    const minWeight = 20;

    // Prosta funkcja skalująca rozmiar czcionki
    const fontSize = (weight) => {
        const minFontSize = 20;
        const maxFontSize = 100;
        return minFontSize + (maxFontSize - minFontSize) * ((weight - minWeight) / (maxWeight - minWeight));
    };

    return { width, height, fontSize };
}
