export function neonFlickerEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes flicker {
                0%, 100% {
                    text-shadow: 0 0 7px rgba(255, 51, 153, 0.6), 0 0 15px rgba(255, 51, 153, 0.4);
                }
                10% {
                    text-shadow: 0 0 10px rgba(255, 51, 153, 1), 0 0 20px rgba(255, 51, 153, 0.8), 0 0 40px rgba(255, 51, 153, 0.6);
                }
                20% {
                    text-shadow: 0 0 8px rgba(255, 51, 153, 0.7), 0 0 18px rgba(255, 51, 153, 0.5), 0 0 35px rgba(255, 51, 153, 0.3);
                }
                50% {
                    text-shadow: 0 0 10px rgba(255, 51, 153, 0.9), 0 0 25px rgba(255, 51, 153, 0.7), 0 0 45px rgba(255, 51, 153, 0.5);
                }
                80% {
                    text-shadow: 0 0 5px rgba(255, 51, 153, 0.5), 0 0 12px rgba(255, 51, 153, 0.3), 0 0 25px rgba(255, 51, 153, 0.1);
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.animation = 'flicker 1.5s infinite alternate';
            element.style.color = '#ff3399'; // neon pink
        });
    });
}
