export function getShadowStyle(shadow) {
    switch (shadow) {
        case 'small':
            return {
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 2,
                shadowOffsetX: 1,
                shadowOffsetY: 1
            };
        case 'medium':
            return {
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 4,
                shadowOffsetX: 2,
                shadowOffsetY: 2
            };
        case 'large':
            return {
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 6,
                shadowOffsetX: 3,
                shadowOffsetY: 3
            };
        case 'none':
        default:
            return {
                shadowColor: 'transparent',
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 0
            };
    }
}
