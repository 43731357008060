import { gsap } from "gsap";

const palettes = {
    default: {
        baseColor: 'rgb(255, 255, 255)',
        glowColor1: 'rgba(255, 0, 255, 1)',
        glowColor2: 'rgba(0, 255, 255, 1)',
        hoverColor: 'rgba(255, 255, 0, 1)'
    },
    coolors: {
        baseColor: '#dbb774',
        glowColor1: '#eeca87',
        glowColor2: '#800000',
        hoverColor: '#ff7f50'
    }
};

export function neonNaturalBlurEffect(selector, paletteName = 'default') {
    const neonElements = document.querySelectorAll(selector);
    const palette = palettes[paletteName] || palettes.default;

    if (neonElements.length === 0) {
        console.warn('No elements with the class or selector found.');
        return;
    }

    neonElements.forEach(element => {
        gsap.set(element, {
            fill: palette.baseColor,
            textDecoration: 'none',
            cursor: 'pointer',
            willChange: 'transform'
        });

        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 0.8,
        });

        tl.to(element, {
            textShadow: `
                0 0 20px ${palette.baseColor},
                0 0 40px ${palette.glowColor1},
                0 0 60px ${palette.glowColor2}
            `
        });

        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.1,
                fill: palette.hoverColor,
                ease: "power1.out",
                overwrite: "all"
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.1,
                fill: palette.baseColor,
                ease: "power1.out",
                overwrite: "all",
                onComplete: () => {
                    tl.resume();
                }
            });
        });
    });
}
