export function neonSmoothTransitionEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes smooth {
                0%, 100% {
                    text-shadow: 0 0 5px rgba(153, 51, 255, 0.6), 0 0 10px rgba(153, 51, 255, 0.4), 0 0 20px rgba(153, 51, 255, 0.2);
                }
                50% {
                    text-shadow: 0 0 15px rgba(153, 51, 255, 0.8), 0 0 30px rgba(153, 51, 255, 0.6), 0 0 45px rgba(153, 51, 255, 0.4);
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.animation = 'smooth 2s ease-in-out infinite alternate';
            element.style.color = '#9933ff'; // neon purple
        });
    });
}
