export function neonGradientEffect(selector) {
    document.addEventListener('DOMContentLoaded', function () {
        const elements = document.querySelectorAll(selector);

        if (elements.length === 0) {
            console.warn('No elements found.');
            return;
        }

        const keyframes = `
            @keyframes gradient {
                0% {
                    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,255,0,1) 50%, rgba(0,0,255,1) 100%);
                    -webkit-background-clip: text;
                    color: transparent;
                }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        elements.forEach(element => {
            element.style.background = 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,255,0,1) 50%, rgba(0,0,255,1) 100%)';
            element.style.webkitBackgroundClip = 'text';
            element.style.color = 'transparent';
            element.style.textShadow = '0 0 5px rgba(255, 0, 0, 0.7), 0 0 10px rgba(0, 255, 0, 0.5), 0 0 15px rgba(0, 0, 255, 0.3)';
        });
    });
}
