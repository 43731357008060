import { neonBlinkEffect } from './neon-effects/neon-blink.js';
import { neonBreathingEffect } from './neon-effects/neon-breathing.js';
import { neonColorTransitionEffect } from './neon-effects/neon-color-transition.js';
import { neonExpandEffect } from './neon-effects/neon-expand.js';
import { neonFlashEffect } from './neon-effects/neon-flash.js';
import { neonFlowEffect } from './neon-effects/neon-flow.js';
import { neonMultiColorEffect } from './neon-effects/neon-multi-color.js';
import { neonNaturalBlurEffect } from './neon-effects/neon-natural-blur-effect.js';
import { neonNaturalPulseEffect } from './neon-effects/neon-natural-pulse.js';
import { neonStaticEffect } from './neon-effects/neon-static.js';
import { neonPulseEffect } from './neon-effects/neon-pulse.js';
import { neonShakeEffect } from './neon-effects/neon-shake.js';
import { neonFlickerEffect } from './neon-effects/neon-flicker.js';
import { neonGradientEffect } from './neon-effects/neon-gradient.js';
import { neonFadeInOutEffect } from './neon-effects/neon-fade-in-out.js';
import { neonDensityEffect } from './neon-effects/neon-density.js';
import { neonSmoothTransitionEffect } from './neon-effects/neon-smooth-transition.js';
import { neonGlowEffect } from './neon-effects/neon-glow.js';
import { neonBackShadowEffect } from './neon-effects/neon-back-shadow.js';
import { neonSparkleEffect } from './neon-effects/neon-sparkle.js';

export function applyNeonEffect(style) {
    var selector = "." + style;
    switch (style) {
        case 'neon-static':
            neonStaticEffect(selector);
            break;
        case 'neon-pulse':
            neonPulseEffect(selector);
            break;
        case 'neon-multi-color':
            neonMultiColorEffect(selector);
            break;
        case 'neon-blink':
            neonBlinkEffect(selector);
            break;
        case 'neon-color-transition':
            neonColorTransitionEffect(selector);
            break;
        case 'neon-breathing':
            neonBreathingEffect(selector);
            break;
        case 'neon-shake':
            neonShakeEffect(selector);
            break;
        case 'neon-expand':
            neonExpandEffect(selector);
            break;
        case 'neon-flash':
            neonFlashEffect(selector);
            break;
        case 'neon-flow':
            neonFlowEffect(selector);
            break;
        case 'neon-natural-pulse':
            neonNaturalPulseEffect(selector);
            break;
        case 'neon-natural-blur':
            neonNaturalBlurEffect(selector);
            break;
        case 'neon-flicker':
            neonFlickerEffect(selector);
            break;
        case 'neon-gradient':
            neonGradientEffect(selector);
            break;
        case 'neon-fade-in-out':
            neonFadeInOutEffect(selector);
            break;
        case 'neon-density':
            neonDensityEffect(selector);
            break;
        case 'neon-smooth-transition':
            neonSmoothTransitionEffect(selector);
            break;
        case 'neon-glow':
            neonGlowEffect(selector);
            break;
        case 'neon-back-shadow':
            neonBackShadowEffect(selector);
            break;
        case 'neon-sparkle':
            neonSparkleEffect(selector);
            break;
        default:
            console.warn(`Unknown neon effect: ${style}`);
            break;
    }
}